import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { doSendToTop } from '@utils/helper';
import backTopIcon from '@images/up-arrow.svg';

export const Button = styled.button`
  border: 0;
  margin: 0;
  padding: 0.8rem;
  border-radius: 25rem;
  position: fixed;
  bottom: 21rem;
  right: 0.2rem;
  background-image: ${props => `linear-gradient(to left, ${props.theme.primaryColor}, ${props.theme.secondaryColor})`};
  opacity: ${props => props.show ? 1 : 0};
  visibility: ${props => props.show ? 'visible' : 'hidden'};
  transition: all 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
`;

export const Icon = styled.img`
  width: 1.6rem;
  height: 1.6rem;
`;

export default function SendToTopBtn ({ show }) {
  return (
    <Button show={show} onClick={doSendToTop}><Icon src={backTopIcon} /></Button>
  );
}