import styled from 'styled-components';
import footerBg from '@images/footer-bg.jpg';

export const Container = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  background-image: url(${footerBg});
  background-size: 190%;
  background-position: bottom center;
  background-repeat: no-repeat;
  color: #fff;
  min-height: 20rem;
  max-width: 1440px;
  margin: 0 auto;
`;

export const Content = styled.div`
  margin: 6rem 1.6rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 2.8rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
  transition: all 0.4s ease;
`;

export const FollowBox = styled.div`
  margin: 1rem 0 2rem;
  min-width: 16rem;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 992px) {
    margin-bottom: 1rem;
  }
`;

export const FollowTitle= styled.h2`
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
  text-align: center;
  color: ${props => props.theme.white};
`;

export const Icon = styled.img`
  width: 2.4rem;
  height: 2.4rem;
  @media only screen and (min-width: 992px) {
    width: 3.2rem;
    height: 3.2rem;
  }
`;

export const MediaLink = styled.a.attrs({
  target: '_blank',
})`
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  background-color: transparent;
`;

export const SocialMedia = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  @media only screen and (min-width: 992px) {
    margin-top: 1rem;
  }

  ${MediaLink} {
    margin: 0 6px;
  }
`;

export const Rights = styled.div`
  font-weight: 200;
  font-size: 1.4rem;
`;