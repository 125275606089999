import React from 'react';
import FooterSeperator from './footer-seperator';
import {
  Container,
  Content,
  FollowBox,
  FollowTitle,
  Icon,
  MediaLink,
  Rights,
  SocialMedia,
  Title,
} from './style';

import twitterIcon from '@images/twitter.svg';
import githubIcon from '@images/github.svg';
import linkedInIcon from '@images/linkedin.svg';
import behanceIcon from '@images/behance.svg';
import stackoverflowIcon from '@images/stackoverflow.svg';


export default function Footer({ title, alter }) {
  return (
    <Container>
      <FooterSeperator alter={alter} />
      <Content>
        <Title>{title}</Title>
        <FollowBox>
          <FollowTitle>Follow me</FollowTitle>
          <SocialMedia>
            <MediaLink href="https://twitter.com/Belal_Mazlom">
              <Icon src={twitterIcon} />
            </MediaLink>
            <MediaLink href="https://github.com/belal-mazlom">
              <Icon src={githubIcon} />
            </MediaLink>
            <MediaLink href="https://www.linkedin.com/in/belalmazlom">
              <Icon src={linkedInIcon} />
            </MediaLink>
            <MediaLink href="https://stackoverflow.com/users/292927/belal-mazlom">
              <Icon src={stackoverflowIcon} />
            </MediaLink>
            <MediaLink href="https://www.behance.net/belalmazlom">
              <Icon src={behanceIcon} />
            </MediaLink>
          </SocialMedia>
        </FollowBox>
        <Rights>© {new Date().getFullYear()} All rights reserved</Rights>
      </Content>
    </Container>
  );
}
