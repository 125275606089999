import React from 'react';
import { Link } from 'gatsby';
import { Nav, NavItem } from './style';

function doClick(event, target) {
  if (typeof window !== 'undefined') {
    const element = document.getElementById(target);
    if (element) {
      window.scrollTo(0, element.offsetTop + 150);
    }
    event.preventDefault();
  }
}

export default function Menu({ sections, isHome }) {
  return (
    <Nav>
      {sections &&
        sections.map(({ title, target, url }) => (
          <NavItem key={`menu-${target}`}>
            {isHome ? (
              <a
                href={url}
                onClick={e => {
                  doClick(e, target);
                  return false;
                }}>
                {title}
              </a>
            ) : (
              <Link to={url}>{title}</Link>
            )}
          </NavItem>
        ))}
    </Nav>
  );
}
