import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

const Container = styled.div`
  background-color: transparent;
  position: absolute;
  left: 0;
  right: 0;
  top: 0rem;
`;

export default function FooterSeperator(props) {
  const themeContext = useContext(ThemeContext);

  return (
    <Container>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100"
        viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <rect
          x="0"
          y="-5"
          width="99"
          height="5"
          fill={
            props.alter ? themeContext.secondaryBgColor : themeContext.bgColor
          }></rect>
        <polygon
          points={props.alter ? '0,0 100,0 0,100' : '0,100 100,0 100,0'}
          fill={
            props.alter ? themeContext.secondaryBgColor : themeContext.bgColor
          }
        />
      </svg>
    </Container>
  );
}
