import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
  HeaderContainer,
  Title,
  TitleLink,
  TopHeader,
  BioBox,
  BioImage,
} from './style';
import Menu from './menu';
import BurgerMenu from './burger-menu';

export default function Header({
  siteTitle,
  sections,
  stick,
  hideBio,
  isHome,
}) {
  return (
    <HeaderContainer hideBio={hideBio}>
      <TopHeader stick={stick}>
        <div>
          <Title>
            <TitleLink to="/">{siteTitle}</TitleLink>
          </Title>
          <Menu sections={sections} isHome={isHome} />
          <BurgerMenu sections={sections} />
        </div>
      </TopHeader>
      {!hideBio && (
        <BioBox>
          <BioImage>
            <StaticImage
              src="./my-image.png"
              alt="Belal Al Mazlom"
              quality={95}
              width={240}
            />
          </BioImage>
        </BioBox>
      )}
    </HeaderContainer>
  );
}
