import styled, { createGlobalStyle } from 'styled-components';
import calendarIcon from '@images/calendar.svg';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Dosis;
    src: url(${require('@assets/fonts/dosis.ttf')});
  }
  html {
    font-size: 62.5%;
    height: 100%;
    scroll-behavior: smooth;
  }
  body {
    color: ${props => props.theme.txtColor};
    background-color: ${props => props.theme.bgColor};
    height: 100%;
    font-family: Dosis, sans-serif;
    margin: 0;
  }
  .popupbox-content {
    position: relative;
  }
`;

export const Button = styled.button`
  background-color: ${props =>
    props.alter ? props.theme.secondaryColor : props.theme.primaryColor};
  color: ${props => props.theme.white};
  padding: 1rem 3rem;
  border: 0;
  border-radius: 0.8rem;
  margin: 2rem 0;
  cursor: pointer;
`;

export const Main = styled.main`
  min-height: 50rem;
  position: relative;
`;

export const Section = styled.div`
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  padding: ${props =>
    props.noPadding ? '0 1.6rem 12rem' : '6rem 1.6rem 12rem'};
  background-color: ${props =>
    props.alter ? props.theme.secondaryBgColor : props.theme.bgColor};
  &:last-child {
    padding-bottom: 0rem;
  }
  @media only screen and (min-width: 992px) {
    padding-bottom: ${props => (props.noPadding ? '0' : '21rem')};
  }
`;

export const SectionTitle = styled.h1`
  font-size: 2.1rem;
  font-weight: 700;
  text-align: center;
  color: ${props => props.theme.txtColor};
  border-bottom: 0.2rem solid ${props => props.theme.primaryColor};
  margin: 0 0.6rem;
  padding: 0 0 1rem 0;
  @media only screen and (min-width: 992px) {
    margin: 0 auto;
    max-width: 70rem;
    font-size: 2.8rem;
  }
`;

export const SectionContent = styled.div`
  color: ${props => props.theme.txtColor};
  font-size: 1.8rem;
  font-weight: 500;
  padding: 2rem 4.6rem;
  text-align: ${props => (props.align ? props.align : 'left')};
  @media only screen and (min-width: 992px) {
    font-size: 2.1rem;
  }
`;

export const SButtonsContainer = styled.div`
  margin: 1rem 0 3rem;
  display: flex;
  justify-content: center;

  > a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-decoration: none;
  }
`;

export const SectionButton = styled.div`
  background-color: ${props =>
    props.alter ? props.theme.secondaryColor : props.theme.primaryColor};
  width: 14rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1rem 3rem 1rem;
  @media only screen and (min-width: 992px) {
    width: 18rem;
    height: 20rem;
  }
`;

export const SBTitle = styled.h2`
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  color: ${props => props.theme.white};
  padding: 0.8rem 0 0.8rem 0;
  @media only screen and (min-width: 992px) {
    font-size: 2.1rem;
  }
`;

export const SBIcon = styled.img`
  width: 10rem;
  height: 10rem;
`;

export const Date = styled.span`
  display: inline-block;
  color: ${props => props.theme.txtColor};
  background-image: url(${calendarIcon});
  background-size: 2rem;
  background-repeat: no-repeat;
  padding: 0 0 0 2.8rem;
  font-size: 1.8rem;
  margin: 0 0 0 1rem;
`;

export const Gallery = styled.div`
  padding: 0.4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 234px;
  margin: 20px auto 10px;
  @media only screen and (min-width: 768px) {
    max-width: none;
  }
`;

export const ProjectImage = styled.button`
  border-radius: 0.8rem;
  border: 0.1rem solid ${props => props.theme.white};
  box-shadow: 0 0 0 0.1rem ${props => props.theme.primaryColor};
  margin: 1rem 1rem 1rem 0;
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 0;
  cursor: pointer;
`;

export const PopupBox = styled.div``;

export const NextBtn = styled.button`
  position: absolute;
  top: 50%;
  left: 0.4rem;
  border: 0;
  background-color: transparent;
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  outline: none;
`;

export const Container = styled.div`
  margin-top: 24px;
`;
