import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import headerBg from '@images/header-bg.jpg';

export const HeaderContainer = styled.header`
  box-sizing: border-box;
  margin: 0 auto;
  padding-bottom: 1rem;
  width: 100%;
  max-width: 1440px;
  background-color: transparent;
  position: relative;
  ${props =>
    props.hideBio
      ? css`
          height: 5.6rem;
        `
      : css`
          height: 22rem;
          background-image: ${props =>
            props.hideBio ? 'none' : `url(${headerBg})`};
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          @media only screen and (min-width: 992px) {
            height: 36rem;
          }
        `};
`;

export const TopHeader = styled.div`
  background-color: #00000078;
  position: fixed;
  width: 100%;
  max-width: 1440px;
  z-index: 20;
  ${props =>
    props.stick &&
    css`
      left: 0;
      right: 0;
      max-width: none;
      background-image: linear-gradient(
        to left,
        ${props.theme.primaryColor},
        ${props.theme.secondaryColor}
      );
      ${NavItem} {
        a {
          text-shadow: none;
        }
      }
    `};

  > div {
    display: flex;
    justify-content: space-between;
    transition: all 0.4s ease;
    padding: 1rem 1.6rem;
    max-width: 1440px;
    margin: 0 auto;
    box-sizing: border-box;
    @media only screen and (min-width: 992px) {
      padding: 1rem 3.2rem;
    }
  }
`;

export const Title = styled.div`
  font-size: 2.8rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
  line-height: 1.2;
  transition: all 0.4s ease;
`;

export const TitleLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.white};
`;

export const Nav = styled.div`
  display: none;
  align-items: center;
  @media only screen and (min-width: 680px) {
    display: flex;
  }
`;

export const NavItem = styled.div`
  padding: 0 1rem;
  a {
    font-size: 2.1rem;
    color: ${props => props.theme.white};
    text-decoration: none;
    text-shadow: 0 2px 2px #000;
    font-weight: 700;
    :hover {
      text-decoration: underline;
    }
  }
`;

export const BurgerLink = styled.button`
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background-color: transparent;
  @media only screen and (min-width: 680px) {
    display: none;
  }
`;

export const BurgerIcon = styled.img`
  width: 2.4rem;
  height: 2.4rem;
`;

export const BurgerPopup = styled.div`
  background-color: ${props => props.theme.bgColor};
  border: 0.2rem solid ${props => props.theme.primaryColor};
  position: fixed;
  margin: 0 auto;
  padding: 1.6rem 1.6rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.8rem;
  top: 6rem;
  left: 1.6rem;
  right: 1.6rem;
  max-width: 40rem;
  z-index: 90;
  transition: all 0.4s ease;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0rem 0.4rem 10px 4px ${props => props.theme.primaryShadow};
  &.active {
    visibility: visible;
    opacity: 1;
  }
`;

export const CloseBtn = styled.button`
  position: absolute;
  top: 1rem;
  left: 0.4rem;
  border: 0;
  background-color: transparent;
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  outline: none;
`;

export const CloseIcon = styled.img`
  width: 1.6rem;
  height: 1.6rem;
`;

export const BurgerMenuItem = styled.a`
  text-align: center;
  text-decoration: none;
  color: ${props => props.theme.txtColor};
  font-size: 2.1rem;
  font-weight: 600;
  letter-spacing: 0.2rem;
  margin-bottom: 0.8rem;
`;

export const BioBox = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 1.6rem;
  @media only screen and (min-width: 992px) {
    left: 3.2rem;
  }
`;

export const BioImage = styled.div`
  width: 15rem;
  height: 15rem;
  @media only screen and (min-width: 992px) {
    width: 24rem;
    height: 24rem;
  }
`;
