
export const defaultTheme = {
  primaryColor: '#6a14d1',
  secondaryColor: '#c82090',
  txtColor: '#333333',
  bgColor: '#FFFFFF',
  secondaryBgColor: '#F0E7FA',
  borderColor: '#6a14d1',
  white: '#FFFFFF',
  black: '#333333',
  primaryShadow: 'rgba(106, 20, 209, 0.39)',
}

export const darkTheme = {
  ...defaultTheme,
  txtColor: '#FFFFFF',
  bgColor: '#333333',
  secondaryBgColor: '#965adf',
};