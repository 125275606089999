import React, { useState } from 'react';
import {
  BurgerIcon,
  BurgerLink,
  BurgerMenuItem,
  BurgerPopup,
  CloseBtn,
  CloseIcon,
} from './style';

import menuIcon from '@images/menu.svg';
import closeIcon from '@images/close.svg';

export default function BurgerMenu({ sections }) {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <BurgerLink onClick={() => setShowMenu(state => !state)}>
        <BurgerIcon src={menuIcon} />
      </BurgerLink>
      <BurgerPopup className={showMenu ? 'active' : ''}>
        <CloseBtn onClick={() => setShowMenu(false)}>
          <CloseIcon src={closeIcon} />
        </CloseBtn>
        <>
          {sections &&
            sections.map(({ title, target, url }) => (
              <BurgerMenuItem
                key={`bmenu-${target}`}
                href={url}
                onClick={e => {
                  if (typeof window !== 'undefined') {
                    const element = document.getElementById(target);
                    if (element) {
                      window.scrollTo(0, element.offsetTop + 150);
                      setShowMenu(state => !state);
                      e.preventDefault();
                    }
                    return false;
                  }
                }}>
                {title}
              </BurgerMenuItem>
            ))}
        </>
      </BurgerPopup>
    </>
  );
}
