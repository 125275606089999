import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { Main, GlobalStyle } from '@components/styledComponents/index';
import { defaultTheme, darkTheme } from '@components/styledComponents/theme';
import Header from './header/index';
import Footer from './footer/index';
import SendToTopBtn from '../components/sendToTop';

const Layout = ({ children, alterFooter, stickHeader, hideBio }) => {
  const [theme, setTheme] = useState(defaultTheme);
  const [showSendTop, setShowSendTop] = useState(false);

  const {
    site: {
      siteMetadata: { title, sections },
    },
  } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          sections {
            title
            target
            url
          }
        }
      }
    }
  `);

  useEffect(() => {
    function onScroll() {
      if (window.scrollY > 50) {
        setShowSendTop(true);
      } else {
        setShowSendTop(false);
      }
    }

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <>
        <Header
          siteTitle={title}
          sections={sections}
          stick={showSendTop || stickHeader}
          hideBio={hideBio}
        />
        <Main>{children}</Main>
        <Footer title={title} alter={alterFooter} />
        <SendToTopBtn show={showSendTop} />
      </>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
